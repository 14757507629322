import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import DisplayContent from "../components/DisplayContent";
import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import FullWidthImage from "../components/FullWidthImage";

import DisplayContentHeader from "../components/DisplayContentHeader";
import BlogRoll from "../components/BlogRoll";
import Pagination from "../components/Pagination";
import { useLocation } from "@reach/router";
const pluralize = require("pluralize");
// eslint-disable-next-line
export const CategoryPostTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  category,
  posts,
  authors,
  pageContext,
  helmet,
  header,
  layout,
  videos,
  previewSummary,
}) => {
  let templateName = "category-post",
    heroVideo,
    heroImage,
    smallImage,
    footerImage,
    renderMode = "blogroll",
    renderSize = 4,
    paginationPosition = "below",
    renderWeight = "normal";

  if (header && header.layout && header.layout.render) {
    if (header.layout.render.category) {
      // eslint-disable-next-line
      renderMode = header.layout.render.category;
    }
    if (header.layout.render.size) {
      // eslint-disable-next-line
      renderSize = header.layout.render.size;
    }

    if (header.layout.render.weight) {
      // eslint-disable-next-line
      renderWeight = header.layout.render.weight;
    }
    if (header.layout.render.position) {
      paginationPosition = header.layout.render.position;
    }
    if (header && header.layout && header.layout.render) {
      layout = header.layout.render;
    }
  }

  layout.render = layout;
  layout.position = "below";

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    // eslint-disable-next-line
    if (images.smallImage) smallImage = images.smallImage;
  }

  // eslint-disable-next-line
  if (videos && videos.hero) heroVideo = videos.hero;
  if (!layout.position) layout.position = "below";

  const showTitle = options.showTitle;

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroImage={heroImage}
        title={`${titleCase(category)}`}
        subheading="Category"
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={true}
      />

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <div className="">
              <DisplayContentHeader
                title={category}
                parent="Categories"
                parentSlug="/categories"
                grandparent="Blog"
                grandparentSlug="/blog"
                showColon={true}
              />
            </div>

            <DisplayContent
              id={templateName}
              previewContent={previewSummary}
              body={<></>}
              layout={layout}
              element={
                <>
                  <BlogRoll
                    posts={posts}
                    authors={authors}
                    titleMode="h3.5"
                    title={`${category} Blog ${pluralize(
                      "Post",
                      posts.length
                    )}:`}
                    emptyMessage="This category does not have any entries yet."
                  />
                </>
              }
              pagination={<Pagination pageContext={pageContext} />}
              paginationPosition={paginationPosition}
              pages={pageContext ? pageContext.numberOfPages : 1}
            />
          </div>
        </div>

        {footerImage ? (
          <FullWidthImage
            img={footerImage}
            subheading={footer}
            imgPosition="center"
            className="footer-image-text"
            showTitle={true}
          />
        ) : null}
      </section>
    </div>
  );
};

CategoryPostTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const CategoryPost = ({ data, pageContext }) => {
  let { markdownRemark: post } = data;
  let { allMarkdownRemark: posts } = data;
  let { categoryPosts } = data;
  let { authors } = data;
  let { header } = data;

  let imageSrc;

  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }

  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  let seoTitle = "Category > " + pageContext.category;

  if (pageContext.pageNumber) {
    seoTitle = seoTitle + ", Page " + pageContext.humanPageNumber;
  }
  categoryPosts = categoryPosts.edges;

  let { pathname } = useLocation();

  pathname = "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2];

  let thisCategory = categoryPosts.filter(
    (posts) =>
      posts.node.fields.slug === pathname ||
      posts.node.fields.slug === addTrailingSlash(pathname)
  );

  if (thisCategory && thisCategory[0] && thisCategory[0].node) {
    thisCategory = thisCategory[0].node;
  } else {
    thisCategory = {};
  }
  if (!thisCategory.frontmatter) thisCategory.frontmatter = {};
  if (!thisCategory.frontmatter.titles) thisCategory.frontmatter.titles = {};

  authors = authors.nodes;
  posts = posts.edges;

  return (
    <Layout>
      <CategoryPostTemplate
        title={thisCategory.frontmatter.title || `Category`}
        subtitle={
          thisCategory.frontmatter.titles.subtitle ||
          `"${pageContext.category}"`
        }
        footer={
          thisCategory.frontmatter.titles.footer ||
          post.frontmatter.titles.footer
        }
        images={thisCategory.frontmatter.images || post.frontmatter.images}
        options={thisCategory.frontmatter.options || post.frontmatter.options}
        breadcrumbs={
          thisCategory.frontmatter.breadcrumbs || post.frontmatter.breadcrumbs
        }
        author={thisCategory.frontmatter.author || post.frontmatter.author}
        creationDate={
          thisCategory.frontmatter.creationDate || post.frontmatter.creationDate
        }
        summary={thisCategory.frontmatter.summary || post.frontmatter.summary}
        body={thisCategory.html || post.html}
        category={pageContext.category}
        posts={posts}
        authors={authors}
        pageContext={pageContext}
        helmet={
          <Seo
            title={seoTitle}
            summary={
              thisCategory.frontmatter.summary || post.frontmatter.summary
            }
            image={imageSrc}
            article={false}
          />
        }
        header={header.frontmatter}
      />
    </Layout>
  );
};

CategoryPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CategoryPost;

export const categoryPostQuery = graphql`
  query CategoryPost($category: String!, $skip: Int!, $limit: Int!) {
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
          social {
            title
            slug
          }
          images {
            smallImage {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "category-page" } } }
    ) {
      html
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
          showTitlesSwapped
          showDisplayContentHeader
        }
        summary
      }
    }
    categoryPosts: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "category-post" } } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            name
            images {
              hero {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
            options {
              showTitle
            }
            summary
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___creationDate] }
      filter: {
        frontmatter: {
          advanced: { templateKey: { in: ["blog-post", "video-post"] } }
          category: { eq: $category }
        }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            advanced {
              templateKey
            }
            category
            creationDate
            author
            options {
              showTitle
            }
            summary
            flags {
              featured
            }
            tags
            images {
              hero {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 900)
                }
              }
            }
          }
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        layout {
          columns
          render {
            size
            category
            position
            separator
            weight
          }
        }
        breadcrumbs {
          positionClass
          separatorClass
          position
          showDate
          showCurrent
        }
      }
    }
  }
`;

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function addTrailingSlash(url) {
  if (url.charAt(url.length - 1) !== "/") {
    url = url + "/";
  }
  return url;
}
